import React, { useState, useEffect, useRef, useCallback, ReactNode, forwardRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { theme } from "@upsolve/ui";
import ChatIcon from "./ChatIcon";
import { IChatMessage, useChat } from "./useChat";
import CloseButton from "../../../static/images/close-button.svg";
import SendIcon from "./images/send-icon.svg";
import Sparkles from "./images/sparkles-icon-dark.svg";
import PageIcon from "./images/page-icon.svg";
import MessageIcon from "./images/chat-icon.svg";
import { QuestionAnswerFormat } from "./QuestionAnswerFormat";
const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const pulse = keyframes`
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
`;

const ChatWindow = styled.div<{ isOpen: boolean; isMidClosingAnimation: boolean }>`
  position: fixed;
  z-index: 5000;
  bottom: 20px;
  right: 20px;
  width: 350px;
  height: 600px;
  background-color: ${theme.colors.white[900]};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.isOpen || props.isMidClosingAnimation ? "flex" : "none")};
  flex-direction: column;
  overflow: hidden;
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${slideUp} 0.3s ease-out forwards
        `
      : props.isMidClosingAnimation
      ? css`
          ${slideDown} 0.2s ease-in forwards
        `
      : "none"};

  @media (max-height: ${(props) => props.theme.breakpoints[500]}) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const ChatHeader = styled.div`
  color: ${theme.colors.brand[500]};
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .chat-title {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  svg {
    width: 32px;
  }
  button {
    all: unset;
    cursor: pointer;
  }
`;

const ChatMessages = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
`;

const UserMessage = styled.div`
  margin-bottom: 14px;
  padding: 16px 24px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  position: relative;
  width: 100%;
  min-width: 0;
  background-color: #f5f5f8;
  color: black;
`;

const SystemMessage = styled.div`
  margin-bottom: 14px;
  padding: 16px 24px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  position: relative;
  width: 100%;
  min-width: 0;

  background-color: #eceffd;
  color: black;

  .title {
    color: ${theme.colors.brand[500]};
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    span {
      padding-left: 5px;
    }
  }
`;

const Message = forwardRef<HTMLDivElement, { isUser: boolean; children: ReactNode }>((props, ref) => {
  if (props.isUser) {
    return <UserMessage ref={ref}>{props.children}</UserMessage>;
  }
  return (
    <SystemMessage ref={ref}>
      <div className="title">
        <Sparkles />
        <span>Assistant</span>
      </div>
      {props.children}
    </SystemMessage>
  );
});

const SendMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const SendMessageBox = styled.div`
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  border-radius: 12px;
`;

const SendMessageInputWrapper = styled.div`
  flex-grow: 1;
  background-color: #eef1fe;
  border: 1px solid #3c5dff;
  border-radius: 12px 0 0 12px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SendMessageIconWrapper = styled.span`
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SendMessageInputField = styled.input`
  background-color: #eef1fe;
  border: 0;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #3c5dff;
    opacity: 50%;
  }
  flex-grow: 1;
  padding: 8px 8px;
  overflow: hidden;
`;

const SendButton = styled.button`
  height: 50px;
  background-color: ${theme.colors.brand[500]};
  color: ${theme.colors.white[900]};
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0 12px 12px 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const LoadingDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.brand[500]};
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  animation: ${css`
    ${pulse} 1.4s infinite ease-in-out both
  `};

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const StyledSource = styled.button`
  background: none;
  border: none;
  margin-top: 6px;
  padding: 0;
  cursor: pointer;
  color: ${theme.colors.brand[500]};
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  min-height: 22px;
  transition: color 0.2s ease;
  &:hover {
    color: ${theme.colors.brand[600]};
  }
  span {
    padding-left: 6px;
  }
`;

const ContactSupportButton = styled.button`
  color: ${theme.colors.brand[500]};
  font-size: 13px;
  background: none;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  text-align: left;
  border-radius: 4px;
`;

const MessageContent = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const SourceButton: React.FC<{ article: { title: string; url: string } }> = ({ article }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (article.url) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.opener = null;
        newWindow.location.href = article.url;
      }
    }
  };

  return (
    <StyledSource onClick={handleClick} aria-label={`Open article: ${article.title}`}>
      <PageIcon />
      <span>SOURCE</span>
    </StyledSource>
  );
};

export const DebtAdvisorChat = (props: {
  optionOfInterest: string;
  // TODO: add status quo + result info for all results
}) => {
  const chat = useChat();
  const [isMidClosingAnimation, setIsMidClosingAnimation] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userMessageCount, setUserMessageCount] = useState(0);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const supportButtonRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (userMessageCount >= 5 && supportButtonRef.current) {
      supportButtonRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat.history, userMessageCount]);

  useEffect(() => {
    const userMessages = chat.history.filter((msg) => msg.sender === "user");
    setUserMessageCount(userMessages.length);
  }, [chat.history]);

  const handleOpen = () => {
    chat.setIsOpen(true);
    setIsMidClosingAnimation(false);
  };

  const handleClose = () => {
    setIsMidClosingAnimation(true);
    chat.setIsOpen(false);
    setTimeout(() => {
      setIsMidClosingAnimation(false);
    }, 300);
  };

  const handleSendMessage = async () => {
    if (!userInput.trim() || chat.isLoading) return;
    setUserInput("");
    await chat.askQuestion(userInput, props.optionOfInterest);
  };

  const renderMessage = (chat: IChatMessage) => {
    if (typeof chat.text === "string" && chat.text.includes("Question: ") && chat.text.includes("Answer: ")) {
      return <QuestionAnswerFormat text={chat.text} articleLink={chat.articleLink} />;
    }

    const messageBody =
      typeof chat.text !== "string"
        ? chat.text
        : chat.text.split("\n").map((line, index) => <div key={index}>{line}</div>);

    const articles = chat.articleLink ? JSON.parse(chat.articleLink) : [];
    if (!chat.articleLink || articles.length === 0) {
      return <MessageContent>{messageBody}</MessageContent>;
    }

    return (
      <MessageContent>
        {messageBody}
        <SourceButton article={articles[0]} />
      </MessageContent>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <ChatIcon onClick={handleOpen} />
      <ChatWindow isOpen={chat.isOpen} isMidClosingAnimation={isMidClosingAnimation}>
        <ChatHeader>
          <div className="chat-title">
            <Sparkles />
            <span>Assistant</span>
          </div>
          <button onClick={handleClose}>
            <CloseButton />
          </button>
        </ChatHeader>
        <ChatMessages>
          {chat.history.map((message, index) => (
            <Message
              key={message.id}
              isUser={message.sender === "user"}
              ref={index === chat.history.length - 1 && userMessageCount < 5 ? lastMessageRef : null}
            >
              {renderMessage(message)}
            </Message>
          ))}
          {chat.isLoading && (
            <LoadingWrapper>
              <LoadingDot />
              <LoadingDot />
              <LoadingDot />
            </LoadingWrapper>
          )}
          {userMessageCount >= 5 && (
            <ContactSupportButton
              as="a"
              href="https://calendly.com/clairerobinson/ask-the-upsolve-team"
              target="_blank"
              rel="noopener noreferrer"
              ref={supportButtonRef}
            >
              Contact Support
            </ContactSupportButton>
          )}
        </ChatMessages>
        <SendMessageWrapper>
          <SendMessageBox>
            <SendMessageInputWrapper>
              <SendMessageIconWrapper>
                <MessageIcon />
              </SendMessageIconWrapper>
              <SendMessageInputField
                value={userInput}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    chat.lastTypingTime.current = Date.now();
                  }

                  setUserInput(e.target.value);
                }}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                placeholder="Message..."
              />
            </SendMessageInputWrapper>
            <SendButton onClick={handleSendMessage} disabled={chat.isLoading}>
              <SendIcon />
            </SendButton>
          </SendMessageBox>
        </SendMessageWrapper>
      </ChatWindow>
    </div>
  );
};
