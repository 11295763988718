import React, { useRef, useEffect } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import type { IAxiosRetryConfig } from "axios-retry";
import { useMutation, useQuery, useQueryClient } from "react-query";
import create from "zustand";
import { addYears, format } from "date-fns";
import { v4 as uuidV4 } from "uuid";
import { UPSOLVE_IDENTIFIER_COOKIE } from "@upsolve/shared";
import styled from "styled-components";
import { theme } from "@upsolve/ui";

//TODO: move all this cookie stuff/find out if we can reuse code
const COOKIE_DOMAIN = process.env.NODE_ENV !== "local" ? ".upsolve.org" : "localhost";

const setCookie = (name: string, value: string) => {
  const twoYearsFromNow = addYears(new Date(), 2);
  const cookieParameters = {
    [name]: value,
    domain: COOKIE_DOMAIN,
    expires: format(twoYearsFromNow, "EEE, dd MMM yyyy HH:mm:ss OOO"),
    path: "/",
  };
  document.cookie = Object.entries(cookieParameters)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

const refreshCookie = (name: string) => {
  const oldCookieValue = getCookie(name);
  if (oldCookieValue) {
    setCookie(name, oldCookieValue);
  }
};

const findOrCreateAnonymousId = () => {
  const currentAnonymousId = getCookie(UPSOLVE_IDENTIFIER_COOKIE);
  if (currentAnonymousId) {
    refreshCookie(UPSOLVE_IDENTIFIER_COOKIE);
    return currentAnonymousId;
  }

  const anonymousId = uuidV4();
  setCookie(UPSOLVE_IDENTIFIER_COOKIE, anonymousId);
  return anonymousId;
};

export interface IChatMessage {
  id: number;
  sender: "user" | "ai";
  text: string | React.ReactNode;
  createdAt: string;
  articleLink?: string;
}

interface IChatResponse {
  jobId?: string;
  status: "completed" | "failed" | "processing" | "not_found" | "completed_cleanup";
  progress?: string;
  chatId?: string | null;
  messages?: Array<{
    sender: "user" | "ai";
    text: string;
    articleSuggestions?: Array<{
      title: string;
      url: string;
    }>;
  }>;
  error?: string;
}

interface IChatJobResponse {
  jobId: string;
  status: "processing";
}

interface ISendMessageParams {
  chatId: number | null;
  message: string;
  deviceId: string;
  optionOfInterest: string;
}

interface IChatStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  chatId: number | null;
  setChatId: (chatId: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  history: IChatMessage[];
  appendToHistory: (message: IChatMessage) => void;
  appendMultipleToHistory: (messages: IChatMessage[]) => void;

  lastMessageTimestamp: number;
  setLastMessageTimestamp: (timestamp: number) => void;

  processedMessageIds: string[];
  addProcessedMessageId: (id: string) => void;

  clearProcessedMessageIds: () => void;
}

const StyledChatIntroMessage = styled.div`
  b {
    margin-bottom: 12px;
  }
  hr {
    border: none;
    border-top: 1px solid ${theme.colors.brand["600"]};
    margin: 16px 0;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  ul li {
    display: flex; /* Align checkmark and text in one line */
    align-items: flex-start;
  }
  ul li::before {
    content: "✔";
    font-weight: bold;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .check {
    padding-right: 8px;
  }
  p {
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;

const OpeningMessage: React.FC = () => (
  <StyledChatIntroMessage>
    <b>Meet AI Debt Assist </b>
    <ul>
      <li>Available 24/7 to help you</li>
      <li>Answers to help you reduce your debt</li>
      <li>Answers come only from Upsolve’s 2,000+ expert-written articles on debt topics</li>
      <li>Includes access to an Upsolve staff member if you're struggling</li>
    </ul>
    <hr />
    AI Debt Assistant is a digital helper that’s still learning, so please review all responses. Your questions and the
    AI responses are recorded. Here's what you can expect from us: {" "}
    <a href="https://upsolve.org/learn/upsolve-commitments" target="_blank">
      https://upsolve.org/learn/upsolve-commitments
    </a>
  </StyledChatIntroMessage>
);

const getInitialChatHistory = (): IChatMessage[] => {
  return [
    {
      id: Date.now(),
      sender: "ai",
      text: <OpeningMessage />,
      createdAt: new Date().toISOString(),
    },
  ];
};

const useChatStore = create<IChatStore>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),

  chatId: null,
  setChatId: (chatId: number) => set(() => ({ chatId })),

  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  history: getInitialChatHistory(),
  appendToHistory: (message: IChatMessage) =>
    set((state) => ({
      history: [...state.history, message],
    })),
  appendMultipleToHistory: (messages: IChatMessage[]) => set((state) => ({ history: [...state.history, ...messages] })),

  lastMessageTimestamp: Date.now(),
  setLastMessageTimestamp: (timestamp: number) => set({ lastMessageTimestamp: timestamp }),

  processedMessageIds: [],
  addProcessedMessageId: (id: string) =>
    set((state) => ({
      processedMessageIds: [...state.processedMessageIds, id],
    })),
  clearProcessedMessageIds: () => set({ processedMessageIds: [] }),
}));

const api = axios.create({
  baseURL: UPSOLVE_API_URL,
});

axiosRetry(api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (error.config.method && error.config.method.toLowerCase() !== "get") {
      return false;
    }
    const isServerError = error.response?.status ? error.response.status >= 500 : false;
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || isServerError;
  },
} as IAxiosRetryConfig);

export const useChat = () => {
  const store = useChatStore();
  const lastTypingTime = useRef<number>(0);
  const followUpTimeoutRef = useRef<NodeJS.Timeout>();
  const deviceId = findOrCreateAnonymousId();
  const queryClient = useQueryClient();
  const currentJobRef = useRef<string | null>(null);

  const cleanup = () => {
    if (followUpTimeoutRef.current) {
      clearTimeout(followUpTimeoutRef.current);
    }
    queryClient.cancelQueries(["chat-message"]);
  };

  const cleanupBeforeNewMessage = () => {
    cleanup();
    store.clearProcessedMessageIds();
    currentJobRef.current = null;
  };

  useEffect(() => {
    return cleanup;
  }, [queryClient]);

  const sendMessage = useMutation(async ({ chatId, message, deviceId, optionOfInterest }: ISendMessageParams) => {
    const response = await api.post<IChatJobResponse>("/v1/debtAdvisor/chat/message", {
      chatId,
      query: message,
      deviceId,
      optionOfInterest,
    });
    return response.data;
  });

  const processMessages = async (data: IChatResponse) => {
    if (!data.messages?.length) return;

    for (let i = 0; i < data.messages.length; i++) {
      const message = data.messages[i];
      if (message.sender === "user") continue;
      const chatMessage: IChatMessage = {
        id: Date.now() + i + 1,
        sender: message.sender,
        text: message.text,
        createdAt: new Date().toISOString(),
        articleLink: message.articleSuggestions ? JSON.stringify(message.articleSuggestions) : undefined,
      };
      store.appendToHistory(chatMessage);
      if (i < data.messages.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }
    }
    // Update lastTypingTime after AI messages are processed
    lastTypingTime.current = Date.now();
  };

  useQuery(
    ["chat-message", sendMessage.data?.jobId],
    async () => {
      const response = await api.get<IChatResponse>(`/v1/debtAdvisor/chat/message/${sendMessage.data?.jobId}`);
      return response.data;
    },
    {
      enabled: !!sendMessage.data?.jobId,
      refetchInterval: (data) => {
        if (!data || data.status === "processing") {
          return 1000;
        }
        return false;
      },
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      onSuccess: async (data) => {
        if (data.status === "completed" && data.messages) {
          const messageSignature = data.jobId
            ? `${data.jobId}-${data.messages.length}`
            : `${data.chatId}-${data.messages.length}`;

          if (!store.processedMessageIds.includes(messageSignature)) {
            store.addProcessedMessageId(messageSignature);

            if (!store.chatId && data.chatId) {
              store.setChatId(Number(data.chatId));
            }

            await processMessages(data);

            store.setLastMessageTimestamp(Date.now());
            store.setIsLoading(false);

            // Clear any existing follow-up timeout
            if (followUpTimeoutRef.current) {
              clearTimeout(followUpTimeoutRef.current);
            }

            // Set new follow-up timeout
            followUpTimeoutRef.current = setTimeout(() => {
              const timeSinceLastType = Date.now() - lastTypingTime.current;

              if (timeSinceLastType > 5000) {
                const followUpMessage: IChatMessage = {
                  id: Date.now(),
                  sender: "ai",
                  text: "Do you have any other questions?",
                  createdAt: new Date().toISOString(),
                };
                store.appendToHistory(followUpMessage);
              }
            }, 5000);
          } else {
            store.setIsLoading(false);
          }
        }
      },
      onError: () => {
        const errorMessage: IChatMessage = {
          id: Date.now() + 1,
          sender: "ai",
          text: "Sorry, an error occurred. Please try again.",
          createdAt: new Date().toISOString(),
        };

        //TODO: probably handle errors with its own custom error box
        store.appendToHistory(errorMessage);
        store.setIsLoading(false);
      },
    }
  );

  const askQuestion = async (messageText: string, optionOfInterest: string) => {
    cleanupBeforeNewMessage();
    lastTypingTime.current = Date.now();
    const questionMessage: IChatMessage = {
      id: Date.now(),
      text: messageText,
      sender: "user",
      createdAt: new Date().toISOString(),
    };
    store.appendToHistory(questionMessage);

    store.setIsLoading(true);
    // Send message and poll for response
    const response = await sendMessage.mutateAsync({
      chatId: store.chatId,
      message: messageText,
      deviceId,
      optionOfInterest,
    });

    currentJobRef.current = response.jobId;
  };

  return {
    lastTypingTime,
    isOpen: store.isOpen,
    setIsOpen: store.setIsOpen,
    isLoading: store.isLoading,
    history: store.history,
    askQuestion,
  };
};
