export function formatMonths(months: number): string {
  if (months < 0) {
    throw new Error("Invalid input: negative months");
  }

  if (months === 0) {
    return `Cannot pay off debt with this option`;
  }

  const years = Math.floor(months / 12);
  const remainingMonths = Math.floor(months % 12);

  if (years >= 1) {
    if (remainingMonths > 0) {
      return `${years} year${years > 1 ? "s" : ""}, ${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
    } else {
      return `${years} year${years > 1 ? "s" : ""}`;
    }
  } else {
    return `${Math.round(months)} month${Math.round(months) !== 1 ? "s" : ""}`;
  }
}
